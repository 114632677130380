import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ProductName: '首页'
  },
  getters: {
  },
  mutations: {
    // setValue (state, item) {
    //   state.ProductName = item // 第二个参数随意起个名
    // }
  },
  actions: {
  },
  modules: {
  }
})
