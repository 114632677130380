<template>
  <div id="app" :class="mobile?'mobile':''">
    <!-- <div class="beianz">
      <img src="https://www.hongchuangkj.com/images/1441607667576727.gif"/>
      <p>武汉泓创科技官网备案中..</p></div> -->
    <div style="display: none;">
      <img src="../src/images/hc-logo.jpg" alt="泓创科技logo">
      <h2>武汉泓创科技有限公司官网</h2>
      <p>武汉泓创科技有限公司,武汉泓创, 泓创科技, 整线自动化集成商,码垛机器人,视觉检测,视觉引导,机械臂,工业机器人,智能生产,设备智造,集成专家,创业未来,智能自动化系统</p>
      <p>https://www.hongchuangkj.com</p>
    </div>
    <div class="loading" v-if="loadingStatus">
      <div class="load-gif">
        <img src="./images/hongchuanggif2.gif" alt="加载中..." />
      </div>
    </div>
    <!-- 服务器到期提示 -->
    <!-- <SystemMessage :SystemMessageInfo="SystemMessageInfos"></SystemMessage> -->
    <router-view/>
  </div>
</template>

<script>
import { createWatermark } from './Watermark'
// import wx from 'weixin-js-sdk' // 引入wxjs
// import { shares } from '@/api/index' // 分享的接口  传给后端一个地址(与域名一致)
import AV from 'leancloud-storage'
// import SystemMessage from '@/components/SystemMessage.vue'
export default {
  data: function () {
    return {
      mobile: false,
      loadingStatus: true
      // SystemMessageInfos: {}
    }
  },
  components: {
    // SystemMessage
  },
  created () {
    this.getAppData()
  },
  mounted () {
    // this.addWatermark() // 欠费水印
    // this.getShareInfo() // 调用分享的事件
    this.loadingStatus = false
    // 根据不同路由跳转不同页面
    if (this._isMobile()) {
      // console.log('手机端')
      this.mobile = true
    } else {
      // console.log('pc端')
      this.mobile = false
    }
  },
  methods: {
    // 判断是否是手机端，如果是，返回true
    _isMobile () {
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
    getAppData () {
      const query = new AV.Query('Hongchuang')
      query.get('655ddb499a790104bdf2125a').then((Hongchuang) => {
        this.SystemMessageInfos = Hongchuang.get('WebInfo') // 获取顶部网站内容
      })
    },
    addWatermark () {
      const watermarkCanvas = createWatermark()
      const watermarkDiv = document.createElement('div')
      watermarkDiv.style.pointerEvents = 'none' // 让水印不接收鼠标事件，避免影响页面操作
      watermarkDiv.style.position = 'fixed'
      watermarkDiv.style.top = 0
      watermarkDiv.style.left = 0
      watermarkDiv.style.zIndex = 9999 // 可以适当调整层级确保显示在合适位置
      watermarkDiv.style.width = '100%'
      watermarkDiv.style.height = '100%'
      watermarkDiv.style.backgroundImage = `url(${watermarkCanvas.toDataURL()})`
      document.body.appendChild(watermarkDiv)
    }
    // // vue调用微信的自定义分享
    // getShareInfo () {
    //   this.save = {
    //     url: location.href.split('#')[0] // 只需要传当前页面地址
    //   }
    //   shares(this.save).then(res => {
    //     const config = res.data
    //     wx.config({
    //       appId: config.appId, // appID 公众号的唯一标识
    //       timestamp: config.timestamp, // 生成签名的时间戳
    //       nonceStr: config.nonceStr, //  生成签名的随机串
    //       signature: config.signature, // 生成的签名
    //       jsApiList: [
    //         'updateAppMessageShareData', // 分享到朋友
    //         'updateTimelineShareData' // 分享到朋友圈
    //       ]
    //     })
    //     wx.ready(() => {
    //       const shareData = {
    //         title: '武汉泓创科技有限公司',
    //         desc: '武汉泓创, 泓创科技, 机械臂, 工业机器人, 智能生产, 设备智造, 集成专家, 创业未来,智能自动化系统',
    //         link: 'https://www.hongchuangkj.com', // 分享后的地址
    //         imgUrl:
    //           'https://www.hongchuangkj.com'
    //       }
    //       // 点击要去分享
    //       wx.updateAppMessageShareData(shareData)
    //       wx.updateTimelineShareData(shareData)
    //     })
    //   })
    // }
  }
}
</script>
<style>
body .el-image-viewer__wrapper .el-image-viewer__img{margin-right: 0;}
.beianz{
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background: white;
  text-align: center;
  padding-top: 200px;
}
.beianz img{
  width: 400px;
  height: auto;
  margin: 0 auto;
}
.beianz p{
  font-size: 28px;
  font-weight: bold;
}
*{font-size: 14px; font-family: "SF Pro SC","SF Pro Text","SF Pro Icons","PingFang SC","Helvetica Neue","Helvetica","Arial",sans-serif;margin: 0 auto;padding: 0;}
body{background-color: #f5f5f5;}
.minW{min-width: auto!important;}
.loading {
    width: 100%;
    height: 100%;
    background: white;
    position: fixed;
    z-index: 99;
}
.load-gif {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 200px;
    height: 94px;
}
.load-gif img {
    width: 100%;
    height: auto;
}
@media only screen and (min-width: 1200px) {
  html{
    overflow-x: hidden;
  }
  /* #app{
    overflow-x: hidden;
  } */
}
@media screen and (max-width: 1280px) {
  html{
    /* min-width: 1280px; */
    overflow-x: hidden;
  }
  .TopNavigation {
    width: 100%!important;
  }
  .TopNavigation-phone {
    display: none!important;
  }
  .top-nav-content {
    width: 100%!important;
  }
  .top-nav-content h4 {
    display: none!important;
  }
  .TopNavigation h4 {
    padding-left: 20px!important;
  }
  .top-nav-logo a {
    margin-left: 20px!important;
  }
  .navIcon i {
    display: none!important;
  }

  .top-navbar,
  .top-navbar ul {
    width: 100%!important;
  }
  .top-navbar ul li {
    margin: 0;
    width: 100%!important;
  }.swiper-wrapper,
  .banner-text {
    width: 100% !important;
    margin: 0 !important;
    transform: scale(1)!important;
  }
  .banner-text h1 {
    font-size: 20px !important;
    line-height: 40px !important;
    margin: 42px auto 10px !important;
  }
  .banner-text p{
    text-align: center!important;
    font-size: 12px!important;
    line-height: 24px!important;
  }
  .banner,
  .swiper-slide {
    height: 212px!important;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets{
    bottom: 10px!important;
  }
  .ProductCenter {
    width: 100%!important;
  }
  .p-cantent{
    width: 99%!important;
  }
  .p-canrent-tab {
    overflow: hidden!important;
  }
  .p-cantent ul li {
    height: auto!important;
    display: block!important;
    padding: 10px!important;
  }
  .p-canrent-r h3 {
    font-size: 14px!important;
    line-height: 18px!important;
    overflow: hidden!important;
    text-overflow:ellipsis!important;
    white-space: nowrap!important;
  }
  .p-canrent-tab + p{display: none;}
  .p-cantent-l,
  .p-cantent-l img{
    min-width: 100%!important;
    width: 100%!important;
    height: auto!important;
  }
  .a-content {
    width: 100%!important;
  }
  .a-content-details {
    display: block!important;
    margin: 15px auto!important;
  }
  .AboutUs{
    background-size: cover!important;
    height: 962px!important;
  }
  .a-content-d-i {
    width: 100%!important;
    height: auto!important;
  }
  .Floating {
    transform: scale(0.66)!important;
    right: 0px!important;
    bottom: 10px!important;
    margin: 15px auto!important;
  }
  .a-content-d-t {
    padding:0!important;
    width: 95%!important;
    line-height: 20px!important;
    margin-top: 30px!important;
  }
  .recruitMore {
    left: unset!important;
    right: 15px!important;
    margin: auto!important;
    display: flex!important;
  }
  .recruitMore a {
    display: inline-block !important;
    line-height: 28px !important;
    margin: 0 !important;
    white-space: nowrap!important;
    padding: 0!important;
    padding-left: 0px!important;
    padding-left: 15px!important;
  }
  .recruitMore::after {
    transform: rotate(-90deg) translateY(0px)!important;
  }
  .recruit {
    display: block!important;
  }
  .recruitLi {
    width: 100%!important;
    height: 172px!important;
    margin: 0 auto 15px!important;
    display: block!important;
  }
  .recruiIcon {
    width: 40%!important;
    margin: 0!important;
    line-height: 80px!important;
  }
  .recruiIcon i {
    font-size: 80px!important;
  }
  .recruitState {
    right: 10px!important;
    top: 10px!important;
    padding: 3px 12px!important;
  }
  .recruitLi h4 {
    font-size: 16px!important;
    line-height: 24px!important;
    display: block!important;
    width: 60%!important;
    float: right;
    white-space: nowrap!important;
    transform: translate(-47px,-100px) !important;
    text-align: left !important;
  }
  .recruitLi h1 + h4{
    display: none!important;
  }
  .recruitMore {
    transition: all 300ms!important;
    font-size: 13px!important;
    background: rgb(218, 24, 0)!important;
    width: 100px!important;
    height: 28px!important;
    line-height: 28px!important;
    color: white!important;
    text-align: center!important;
    cursor: pointer!important;
  }
  .map {
    width: 100%!important;
  }
  .recruitLi p {
    display: block!important;
    width: 60%!important;
    transform: translate(50px,-112px)!important;
    text-align: left!important;
    color: #999999!important;
  }
  .recruitLi h1 {
    font-size: 22px!important;
    transform: translate(76px,-33px)!important;
  }
  .bot-navbar {
    height: auto!important;
    padding-bottom: 50px!important;
  }
  .b-navbar {
    height: auto!important;
    padding: 15px!important;
    display: block!important;
  }
  .BottomNavigation {
    width: 100%!important;
  }
  .b-navbar-l {
    height: auto!important;
    margin: 50px auto!important;
  }
  .b-navbar-content h3,
  .b-navbar-content p {
    text-align: center!important;
  }
  .b-navbar-c {
    width: 95%!important;
    margin: 0 auto!important;
    height: auto!important;
  }
  .b-navbar-r {
    margin: 50px auto!important;
    text-align: center!important;
    height: auto!important;
  }
  .b-navbar-r .b-navbar-content h3 {
    text-align: center!important;
  }
  .b-navbar + p {
    width: 95%!important;
  }
  .ques img {
    width: 100%!important;
    transform: translateX(0px)!important;
  }
  .BreadcrumbsModel {
    width: 100%!important;
  }
  .ProductCenter-content {
    width: 100%!important;
    display: block!important;
  }
  .pr-co-mune {
    width: 100%!important;
    min-height: auto!important;
    margin-bottom: 20px!important;
  }
  .co-ri h3 {
    margin-bottom: 5px!important;
  }
  .co-tab {
    white-space: nowrap!important;
    overflow: hidden!important;
  }
  .mobile .Product-more{
    position: unset!important;
    transition: all 300ms!important;
    font-size: 13px!important;
    background: rgb(218, 24, 0)!important;
    width: 100px!important;
    height: 28px!important;
    line-height: 28px!important;
    color: white!important;
    text-align: center!important;
    cursor: pointer!important;
  }
  .co-tab + p {
    color: #999999!important;
    display: -webkit-box!important;
    -webkit-line-clamp: 2!important;
    -webkit-box-orient: vertical!important;
    overflow: hidden!important;
    word-wrap: break-word!important;
    word-break: break-all!important;
  }
  .DetailsViews {
    width: 100%!important;
  }
  .da-co-img + h2 {
    line-height: 24px!important;
  }
  .application ul li {
    height: auto!important;
  }
  .about {
    width: 100%!important;
  }
  .con-t {
    white-space: nowrap!important;
    height: 32px!important;
  }
  .con-t h3 {
    font-size: 14px!important;
    margin: 0!important;
  }
  .tabs,
  .con-t span {
    display: none!important;
  }
}

</style>
