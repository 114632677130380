// watermark.js
export function createWatermark () {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  canvas.width = 200
  canvas.height = 150
  // 设置水印文字的样式，这里可以按需调整字体、颜色、透明度等
  ctx.font = '14px Arial'
  ctx.fillStyle = 'rgba(180, 180, 180, 0.15)'
  // 绘制水印文字，这里示例为倾斜一定角度，你可以根据喜好调整旋转角度等
  ctx.rotate(-0.2)
  ctx.fillText('腾讯云欠费659元，请及时处理', 0, 50)
  return canvas
}
