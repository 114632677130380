import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '首页',
    component: HomeView
  },
  {
    path: '/ProductCenter',
    name: '产品中心',
    redirect: '/ProductCenter',
    component: () => import('../views/ProductCenter.vue'),
    children: [
      {
        path: '/WisdomView',
        name: '智能装配设备',
        component: () => import('../views/WisdomView.vue')
      },
      {
        path: '/QitaView',
        name: '机器人集成',
        component: () => import('../views/QitaView.vue')
      },
      {
        path: '/VisionView',
        name: '视觉检测自动化设备',
        component: () => import('../views/VisionView.vue')
      }
    ]
  },
  {
    path: '/ProductDetails',
    name: '产品详情',
    component: () => import('../views/ProductDetails.vue')
  },
  {
    path: '/AboutView',
    name: '关于我们',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/AboutView',
    name: '企业文化',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/',
    name: '联系我们',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/LoginView',
    name: '管理员登录',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/RecruitView',
    name: '人才招聘',
    redirect: '/WuhanView',
    component: () => import('../views/RecruitView.vue'),
    children: [
      {
        path: '/WuhanView',
        name: '武汉区域',
        component: () => import('../views/WuhanView.vue')
      },
      {
        path: '/OtherView',
        name: '其他区域',
        component: () => import('../views/OtherView.vue')
      }
    ]
  }
]

// 解决replace重复点击报错问题
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}
// 解决push重复点击报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  // mode: 'hash',
  // mode: 'history', // 打包时注释掉
  base: process.env.BASE_URL,
  linkActiveClass: 'router-link-active',
  routes
})

export default router
