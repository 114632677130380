<template>
    <div class="AboutUs">
      <div class="a-content">
        <h2 class="wow animate__animated animate__fadeInUp">{{ AboutUsTitle }}</h2>
        <p class="wow animate__animated animate__fadeInUp">{{ AboutData.AboutUsText }}</p>
        <div class="a-content-details" data-wow-delay="1s">
          <div class="a-content-d-i wow animate__animated animate__fadeInUp"><img :src="AboutData.homeImg" alt="泓创科技"/></div>
          <div class="a-content-d-t">
            <p v-for="item in AboutData.homeAboutUsText" v-bind:key="item.index" class="wow animate__animated animate__fadeInUp">{{ item }}</p>
          </div>
        </div>
        <div class="toAboutus iconfont wow animate__animated animate__fadeInUp" data-wow-delay="2s"><router-link to="/AboutView">了解更多信息</router-link></div>
      </div>
      <div class="Mask"></div>
    </div>
</template>

<script>
import 'animate.css'
import { WOW } from 'wowjs'
export default {
  name: 'AboutUs',
  props: {
    AboutData: Object
  },
  data: function () {
    return {
      AboutUsTitle: '关于我们',
      AboutUsText: '武汉泓创科技有限公司会持续以“ 服务至上” 的经营宗旨，“ 专业管理，诚信为本” ，为中国制造业提供更好的产品和更优质的服务'
    }
  },
  created () {
  },
  mounted () {
    new WOW({ live: false }).init()
  },
  methods: {
  }
}
</script>
<style scoped>
.AboutUs{
  width: 100%;
  height: 680px;
  margin: 50px auto;
  background-size: 100% auto;
  background: url('https://pic.imgdb.cn/item/65549395c458853aef359c88.jpg') no-repeat center center fixed;
  background-attachment: fixed;
  background-size: 100% auto;
  overflow: hidden;
  position: relative;
}
.AboutUs h2 {
    position: relative;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    line-height: 60px;
    margin-bottom: 10px;
}
.AboutUs h2:before{
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  content: '';
  width: 50px;
  height: 4px;
  background: #DA1800;
}
.Mask {
  width: 100%;
  height: 100%;
  background: #00537a;
  position: absolute;
  top: 0;
  opacity: .75;
}
.a-content {
    position: absolute;
    z-index: 1;
    width: 1280px;
    height: inherit;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    color: white;
    padding: 40px 0;
    box-sizing: border-box;
}
.toAboutus {
    padding: 10px 20px;
    border: 1px solid white;
    display: inline;
    border-radius: 20px;
    margin: 0 auto;
    color: white;
    transition: all 300ms;
    cursor: pointer;
}
.toAboutus a{
  color: white;
}
.toAboutus::after {
    content: '\e615';
    color: white;
    transform: rotate(270deg);
    display: inline-block;
    font-size: 14px;
    vertical-align: inherit;
    margin-left: 6px;
}
.toAboutus:hover {
    transition: all 300ms;
    /* background: #00537A; */
    color: white;
}
.toAboutus:hover::after {
    transition: all 300ms;
    color: white;
    transform: translateX(5px) rotate(270deg);
}
.a-content-details {
    display: flex;
    width: inherit;
    height: auto;
    margin: 50px auto;
}
.a-content-d-i {
    width: 522px;
    height: 348px;
}
.a-content-d-i img {
    width: 100%;
    height: auto;
}
.a-content-d-t {
    width: 60%;
    padding: 20px 0 0 20px;
    text-align: left;
}
.a-content-d-t p {
    margin-bottom: 20px;
    text-align: justify;
    text-justify: distribute;
}
.a-content-d-t p:nth-child(2){animation-delay: 300ms;}
.a-content-d-t p:nth-child(3){animation-delay: 600ms;}
</style>
