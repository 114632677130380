<template>
  <div>
    <div class="ProductCenter mobile">
      <h2 class="wow animate__animated animate__fadeInUp">{{ ProductCenterTitle }}</h2>
      <p class="wow animate__animated animate__fadeInUp">{{ ChanPinText }}</p>
      <div class="p-cantent">
        <ul>
          <li class="wow animate__animated animate__fadeInUp" v-for="item in limitedItems" v-bind:key="item.index">
            <div class="p-cantent-l"><img :src="item.imgUrl" alt="产品图片"/></div>
            <div class="p-canrent-r">
              <h3>{{ item.ProductName }}</h3>
              <div class="p-canrent-tab"><span v-for="ite in item.tabList" v-bind:key="ite.index">{{ ite }}</span></div>
              <p>{{ item.describe }}</p>
            </div>
            <div class="Product-more iconfont"><router-link to="/WisdomView">了解更多</router-link></div>
          </li>
        </ul>
        <div class="toProduct wow animate__animated animate__fadeInUp iconfont" data-wow-delay="2s"><router-link to="/WisdomView">查看更多产品</router-link></div>
      </div>
    </div>
  </div>
</template>

<script>
import 'animate.css'
import { WOW } from 'wowjs'
export default {
  name: 'ProductCente',
  props: {
    ProductData: Array,
    ChanPinText: String
  },
  data: function () {
    return {
      ProductCenterTitle: '产品中心'
    }
  },
  computed: {
    limitedItems: function () {
      return this.ProductData.slice(0, 4) // 只返回前三个元素
    }
  },
  watch: {
    limitedItems () {
      this.$nextTick(() => {
        new WOW().init()
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      new WOW().init()
    })
  },
  methods: {
  }
}
</script>
<style scoped>
.ProductCenter {
    width: 1280px;
    height: auto;
    margin: 50px auto;
}
.ProductCenter h2 {
    position: relative;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    line-height: 60px;
    margin-bottom: 10px;
}
.ProductCenter h2::before{
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  content: '';
  width: 50px;
  height: 4px;
  background: #DA1800;

}
.ProductCenter p {
    font-size: 14px;
    text-align: center;
    line-height: 24px;
    -webkit-line-clamp: 2;
    overflow: hidden;
    max-height: 3em;
    text-overflow: ellipsis;
}
.p-cantent {
    width: 100%;
    height: auto;
    margin: 40px auto;
    text-align: center;
}
.p-cantent ul {
    width: inherit;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    margin-bottom: 40px;
}
.p-cantent ul li {
    transition: all 300ms;
    position: relative;
    width: 48%;
    height: 200px;
    background: white;
    margin-bottom: 24px;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    cursor: pointer;
}
.p-cantent ul li:nth-child(1){animation-delay: 0s;}
.p-cantent ul li:nth-child(2){animation-delay: 300ms;}
.p-cantent ul li:nth-child(3){animation-delay: 600ms;}
.p-cantent ul li:nth-child(4){animation-delay: 900ms;}
.p-cantent ul li:hover{
  transition: all 300ms;
  transform: translateY(0px) scale(1.02);
  box-shadow: 0 0 50px rgba(0,0,0,.1);
}
.p-cantent ul li:hover .p-cantent-l img{
  transition: all 300ms;
  transform: scale(1.1);
}
.p-cantent-l {
    width: 170px;
    height: 170px;
    margin-right: 15px;
    overflow: hidden;
    min-width: 170px;
}
.p-cantent-l img {
    width: inherit;
    height: inherit;
    transition: all 300ms;
    cursor: pointer;
}
.p-canrent-r {
    width: 400px;
    height: 170px;
    overflow: hidden;
}
.p-canrent-tab {
    white-space: nowrap;
    width: 100%;
    height: 30px;
    margin-top: 10px;
    text-align: left;
}
.p-canrent-tab span {
    color: #00537A;
    background: rgba(0, 83, 122, .15);
    padding: 0px 6px;
    box-sizing: border-box;
    border-radius: 0px;
    margin-right: 10px;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: ruby-base-container;
}
.p-canrent-r h3 {
    font-size: 18px;
    text-align: left;
    line-height: 26px;
}
.p-canrent-r p {
    text-align: left;
    line-height: 20px;
    -webkit-line-clamp: 2;
    overflow: hidden;
    max-height: 3em;
    text-overflow: ellipsis;
}
.Product-more {
  transition: all 300ms;
  position: absolute;
  bottom: 20px;
  right: 30px;
  font-size: 13px;
  background: rgb(218, 24, 0);
  width: 100px;
  height: 28px;
  line-height: 28px;
  color: white;
  text-align: center;
  cursor: pointer;
}
.Product-more a{
  color: white;
}
.Product-more::after{
  content: '\e615';
  color: white;
  transform: rotate(270deg);
  display: inline-block;
  font-size: 12px;
  vertical-align: bottom;
  margin-left: 5px;
}
.Product-more:hover{
  transition: all 300ms;
  transform: translateX(10px);
}
.toProduct {
    padding: 10px 20px;
    border: 1px solid #00537A;
    display: inline;
    border-radius: 20px;
    margin: 0 auto;
    color: #00537A;
    transition: all 300ms;
    cursor: pointer;
}
.toProduct a{
  color: #00537A;
}
.toProduct::after{
  content: '\e615';
  color: #00537A;
  transform: rotate(270deg);
  display: inline-block;
  font-size: 14px;
  vertical-align: inherit;
  margin-left: 6px;
}
.toProduct:hover{
  background: #00537A;
}
.toProduct:hover a{
  transition: all 300ms;
  color: white;
}
.toProduct:hover::after{
  transition: all 300ms;
  color: white;
  transform: translateX(5px) rotate(270deg);
}
</style>
